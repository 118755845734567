<template>

  <el-dialog
      title="问题反馈"
      v-if="showObj.isShow"
      :visible.sync="showObj.isShow"
      width="952">
    <iframe id="questionBack" ref="questionBack" :src="url" height="400" width='100%'
            frameborder="0"></iframe>
  </el-dialog>

</template>

<script>
import {getUserId} from "../utils/auth";

export default {
  name: "TheQuestionBack",
  props: {
    showObj: {
      type: Object,
      require: true
    }
  },
  data() {
    return {}
  },
  methods: {
    closeIframe() {
      this.showObj.isShow = false
    }
  },
  computed: {
    url: function () {
      let userId = getUserId()
      let urlPrefix = window.location.host
      return `http://wechat.front.igegeda.com/smooth-communication/approval.html?userid=${userId}&url=${urlPrefix}`
    }
  },
  mounted() {
    //监听postMessage传过来的值
    let that = this
    window.addEventListener("message", function (messageEvent) {
      that.closeIframe()
    }, false)
  }
}

</script>

<style scoped>

</style>
